<template>
	<v-list-item class="menu-item" :router-link="link" :to="link">
		<slot name="prepend"></slot>
		<v-list-item-icon v-if="icon">
			<v-icon v-if="expanded" @click="$emit('click')">{{ icon }}</v-icon>
			<v-tooltip right v-else>
				<template v-slot:activator="{on}">
					<v-icon @click="$emit('click')" v-on="on" color="#3F8669">{{ icon }}</v-icon>
				</template>
				{{ text }}
			</v-tooltip>
		</v-list-item-icon>
		<v-list-item-content @click="$emit('click')">
			<v-list-item-title>{{ text }}</v-list-item-title>
		</v-list-item-content>
		<slot name="append"></slot>
	</v-list-item>
</template>

<style lang="less">
@import "../../assets/css/variables.less";
.menu-item {
	cursor: pointer;
	&.v-list-item--active {
		.v-list-item__icon {
			color: @nhsblue;
		}
		background-color: transparent;
	}
	.theme--light.v-list-item--active:hover::before,
	.theme--light.v-list-item--active::before {
		opacity: 0;
	}
}
</style>

<script>
export default {
	name: "NavItem",
	props: {
		icon: { type: String }, // mdi icon name
		text: { type: String }, // the text for the expanded item
		link: { type: String }, // the href if relevant,
		expanded: { type: Boolean, default: true }, // is this currently expanded
	},
};
</script>

<template>
	<v-app>
		<top-nav></top-nav>
		<sidebar></sidebar>

		<v-main class="ma-4">
			<router-view />
		</v-main>
	</v-app>
</template>

<script>
import TopNav from "@c/navigation/Top.vue";
import Sidebar from "@c/navigation/Side.vue";

export default {
	name: "App",

	data: () => ({
		//
	}),
	computed: {
		state() {
			return this.$store.state
		},
		c(){
			return Object.keys( this.state.sectionResponses.data ).length;
		}
	},
	components: {
		TopNav,
		Sidebar,
	},
	created(){
		document.title = "DMA Stakeholder Dashboard";
	}
};
</script>

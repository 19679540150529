export default {
	firestorePath: "emails",
	firestoreRefType: "collection",
	moduleName: "emails",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		reminder({ dispatch, rootState }, {userId, orgId}) {
			let user = rootState.users.data[userId];	
			let organisation = rootState.organisations.data[orgId];	
			var mail = {
				toUids: [userId],
				template: {
					data: {	
						name: user.displayName,
						sender: rootState.auth.data.displayName,
						organisation: organisation.name
					},
					name: "NgyUCEEnZdSflNBjbZ1Q",
				},
			};
			return dispatch("insert", mail);
		},
		fetchDelegationsByAssessment({dispatch}, assessment ){
			dispatch("fetchAndAdd", {params: {where: [
				["assessment", "==", assessment], 
				["template.name", "==", "delegate "]
			]}})
		}
	},
};

export default {
	firestorePath: "config/default",
	firestoreRefType: "doc",
	moduleName: "config",
	statePropName: "data",
	namespaced: true,
	state: {},
	sync: {
		defaultValues: {
			sections: [],
		},
	},
	serverChange: {
		modifiedHook : function(updateStore, doc, _, { dispatch }) {
			dispatch("sections/fetchByIds", doc.sections, { root: true });
			return updateStore(doc);
		},
	},

	getters: {},
	mutations: {},
	actions: {},
	model: {},
};

import firebase from 'firebase'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/", 
    name: "Home", 
    component: () => import(/* webpackChunkName: "Error" */ '../views/Home.vue')
  },
  {
    path: '/error',
    name: 'Error',
    meta: {
      requires_auth: true
    },
    component: () => import(/* webpackChunkName: "Error" */ '../views/Error.vue')
  },
  {
    path: '/overview',
    meta: {
      requires_auth: true
    },
    name: 'Overview',
    component: () => import(/* webpackChunkName: "Overview" */ '../views/Overview.vue')
  },
  {
    path: '/knowledge-base',
    meta: {
      requires_auth: true
    },
    name: 'KnowledgeBase',
    component: () => import(/* webpackChunkName: "KnowledgeBase" */ '../views/KnowledgeBase.vue')
  },
  {
    path: '/data',
    name: 'Data',
    meta: {
      requires_auth: true
    },
    component: () => import(/* webpackChunkName: "Data" */ '../views/Data.vue')
  },
  {
    path: '/stats',
    name: 'Stats',
    meta: {
      requires_auth: true
    },
    component: () => import(/* webpackChunkName: "Stats" */ '../views/Stats.vue')
  },
  {
    path: '/organisation',
    name: 'OrganisationDetail',
    props: true,
    component: () => import(/* webpackChunkName: "OrganisationDetail" */ '../views/OrganisationDetail.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some((record) => record.meta.requires_auth);
	if (requiresAuth && !(await firebase.getCurrentUser())) {
		next("/");
	} else {
		next();
	}
});

export default router

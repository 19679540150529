<template>
	<v-list-item class="px-2 menu-item">
		<v-list-item-icon>
			<v-btn icon @click="dialog = true" color="#3F8669">
				<v-icon>mdi-account-circle</v-icon>
			</v-btn>
		</v-list-item-icon>
		<v-list-item-title @click="dialog = true">
			{{ $t("auth.login") }}
		</v-list-item-title>
		<v-dialog :eager="true" v-model="dialog" width="360px">
			<div id="ig-firebaseui-auth-container"></div>
		</v-dialog>
	</v-list-item>
</template>

<style lang="less">
@import "../../assets/css/variables.less";
@import "../../../node_modules/firebaseui/dist/firebaseui.css";

.firebaseui-id-submit,
.firebaseui-button,
.mdl-button--raised.mdl-button--colored {
	background: @nhsblue !important;
	color: white !important;
}
</style>

<script type="text/javascript">
import firebase from "firebase";
import * as firebaseui from "firebaseui";
export default {
	name: "Login",
	data: () => {
		return {
			dialog: false,
		};
	},
	mounted() {
		var self = this;
		var uiConfig = {
			callbacks: {
				signInSuccessWithAuthResult: function(authResult) {
					authResult.user;
					self.dialog = false;
					self.$store.dispatch("auth/addUserDetails")
				},
			},
			signInOptions: [
				{
					provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
				},
			],
		};
		var ui =
			firebaseui.auth.AuthUI.getInstance() ||
			new firebaseui.auth.AuthUI(firebase.auth());
		ui.start("#ig-firebaseui-auth-container", uiConfig);
	},
};
</script>

export default {
	messages: {
		oops: "You don't seem to have permission to access this page. Please contact support",
	},
	auth: {
		login: "Login",
		logged_in: "Logged In",
		logout: "Logout",
	},
	misc: {
		are_you_sure: "Are you sure?",
		email: "Email",
		copied: "Copied",
		saved: "Saved",
		search: "Search"
	},
	buttons: {
		collapse: "Collapse Menu",
		back: "Back",
		cancel: "Cancel",
		save: "Save",
		download: "Download",
		send: "Send",
		submit: "Submit",
		complete: "Complete",
	},
};

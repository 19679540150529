export default {
	firestorePath: "knowledgeBase",
	firestoreRefType: "collection",
	moduleName: "knowledgeBase",
	statePropName: "data",
	namespaced: true,
	sync: {
		where: [
			["status", "in", ["published"]]
		], 
		defaultValues: {
			status: "published"
		}
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
};

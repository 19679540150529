export default {
	start({ dispatch, getters }) {
		let organisations = getters["auth/limit"];
		dispatch("config/fetchAndAdd");
		dispatch("themes/fetchAndAdd");
		dispatch("categories/fetchById", "service");
		if (!organisations) {
			dispatch("organisations/fetchAndAdd", { clauses: { where: [["test_data", "!=", true ]] } });
		} else {
			dispatch("organisations/fetchByIds", organisations, { clauses: { where: [["test_data", "!=", true]] } });
		}
	},
};

import Vue from "vue";
import Vuex from "vuex";
import { createStore } from "vuex-extensions";
import VuexEasyFirestore from "vuex-easy-firestore";
Vue.use(Vuex);

import state from "./state";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

import firebase from "firebase";
import assessments from "./modules/assessments.js";
import auth from "./modules/auth.js";
import config from "./modules/config.js";
import indicators from "./modules/indicators.js";
import organisations from "./modules/organisations.js";
import questions from "./modules/questions.js";
import questionTemplates from "./modules/questionTemplates.js";
import knowledgeBase from "./modules/knowledgeBase.js";
import polls from "./modules/polls.js";
import sections from "./modules/sections.js";
import sectionResponses from "./modules/sectionResponses.js";
import staffSurveyResponses from "./modules/staffSurveyResponses.js";
import users from "./modules/users.js";
import themes from "./modules/themes.js";

import categories from "./modules/categories.js";
import categoryOptions from "./modules/categoryOptions.js";
import content from "./modules/content.js";
import emails from "./modules/emails.js";

const vxf = VuexEasyFirestore(
	[
		assessments,
		auth,
		categories,
		categoryOptions,
		config,
		content,
		emails,
		indicators,
		knowledgeBase,
		organisations,
		polls,
		questions,
		questionTemplates,
		sections,
		sectionResponses,
		staffSurveyResponses,
		themes,
		users,
	],
	{
		logging: true,
		FirebaseDependency: firebase,
	}
);

const mwstoreutils = {
	actions: {
		fetchByIds: ({ dispatch }, ids) => {
			if (!ids) {
				return;
			}
			ids.forEach((id) => {
				dispatch("fetchById", id);
			});
		},
	},
};
const storeData = {
	actions,
	getters,
	mutations,
	state,
	plugins: [vxf],
	mixins: mwstoreutils,
};

const store = createStore(Vuex.Store, storeData);

export default store;

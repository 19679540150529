import mwsurveyutils from "@/assets/surveyUtilities.js";

export default {
	firestorePath: "staffSurveyResponses",
	firestoreRefType: "collection",
	moduleName: "staffSurveyResponses",
	statePropName: "data",
	namespaced: true,
	sync: {
		guard: ["created_by", "updated_by"],
		defaultValues: {
			status: "not_started",
			data: {},
		},
	},
	state: {},
	getters: {
		responses(state, _, rootState, rootGetters) {
			var responses = Object.values(state.data);
			var survey = rootGetters.staffSurveySection;
			if (!survey) {
				return;
			}
			return responses.flatMap((r) => {
				let response = mwsurveyutils.processResponse(r); 
				return response;
			}).filter( a => a );
		},
	},
	mutations: {},
	actions: {},
};

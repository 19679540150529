export default {
	firestorePath: "polls",
	firestoreRefType: "collection",
	moduleName: "polls",
	statePropName: "data",
	namespaced: true,
	state: {},
	sync: {},
	serverChange: {},
	getters: {},
	mutations: {},
	actions: {
		fetchByAssessment( { dispatch }, assessment) {
			dispatch("fetchAndAdd", {
				clauses: { limit: 0 },
				params: {
					where: [
						["assessment", "==", assessment],
					],
				},
			});
		},
	},
	model: {},
};

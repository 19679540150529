<template>
	<v-app-bar app color="white" flat>
		<v-toolbar-title class="font-weight-light d-none d-md-block">DMA Stakeholder Dashboard</v-toolbar-title>
		<v-spacer></v-spacer>
		<img
			@click="goHome"
			align-with-title
			class="mt-4"
			:height="height"
			src="../../assets/images/aace-logo.jpg"
		/>
	</v-app-bar>
</template>

<style lang="less" scoped>
img {
	cursor: pointer;
}
</style>
<script>
export default {
	name: "TopNav",
	methods: {
		goHome() {
			if (this.$route.path !== "/") {
				this.$router.push("/");
			}
		},
	},
	computed: {
		isMobile(){
			return this.$vuetify.breakpoint.mobile
		},
		height() {
			return this.isMobile ? 50 : 80;
		}
	}
};
</script>

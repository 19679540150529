export default {     
	firestorePath: "sectionResponses",
	firestoreRefType: "collection",
	moduleName: "sectionResponses",
	statePropName: "data",
	namespaced: true,
	state: {
	},
	sync: {
		where: [
			// ["status", "in", ["published"]]
		], 
		defaultValues: {
			status: "published"
		}
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
	model: {
		
	}
};

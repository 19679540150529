export default {
	firestorePath: "questions",
	firestoreRefType: "collection",
	moduleName: "questions",
	statePropName: "data",
	namespaced: true,
	state: {},
	sync: {
		where: [["status", "in", ["published"]]],
		defaultValues: {
			status: "published",
			hideTitle: false,
			indicator: "",
			question_template: "",
		},
	},
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			if (doc.question_template) {
				dispatch("questionTemplates/fetchById", doc.question_template, {
					root: true,
				});
			}
			if (doc.indicator) {
				dispatch("indicators/fetchById", doc.indicator, { root: true });
			}
			return updateStore(doc);
		},
	},

	getters: {
		getQuestionTexts(state, _, rootState) {
			return (id) => {
				let questionText = {
					indicator: "",
					templateTitle: "",
					content: "",
				};
				let question = state.data[id];
				if (question) {
					if (question.content) {
						questionText.content = question.content;
					}
					let indicator = rootState.indicators.data[question.indicator];
					if (indicator) {
						questionText.indicator = indicator.question_title;
					}
					let questionTemplate =
						rootState.questionTemplates.data[question.question_template];
					if (questionTemplate) {
						questionText.templateTitle = questionTemplate.title;
					}
				}
				questionText.string = `${questionText.templateTitle} ${questionText.content} ${questionText.indicator}`;
				return questionText;
			};
		},
		getAnswerOptions(state, _, rootState) {
			return (id) => {
				let question = state.data[id];
				if (!question || !question.question_template) {
					return false;
				}
				return rootState.questionTemplates.data[question.question_template]
					? rootState.questionTemplates.data[question.question_template]
							.answer_options
					: "";
			};
		},
	},
	mutations: {},
	actions: {},
	model: {},
};

export default {     
	firestorePath: "assessments",
	firestoreRefType: "collection",
	moduleName: "assessments",
	statePropName: "data",
	namespaced: true,
	state: {
	},
	sync: {
		where: [
			["status", "in", ["published"]]
		], 
	},
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			let responses = Object.values(doc.responses).flatMap( r => {
				if( typeof r == "string" ){
					return r;
				}
				return Object.values(r)
			});
			dispatch("sectionResponses/fetchByIds", responses,  {root: true});
			dispatch("users/fetchByIds", Object.keys(doc.users) )
			dispatch("polls/fetchAndAdd", { clauses: { where: [["assessment", "==", doc.id]] } },  {root: true});
			return updateStore(doc);
		},
	},

	getters: {
	},
	mutations: {
	},
	actions: {
	},
	model: {
		
	}
};

<template>
	<span>
		<nav-item
			@click="dialog = true"
			color="#3F8669"
			icon="mdi-logout-variant"
			:text="$t('auth.logout')"
		></nav-item>
		<mw-dialog
			v-model="dialog"
			small
			submitBtn
			cancelBtn
			@submit="logout"
			:title="$t('misc.are_you_sure')"
		>
			<template v-slot:dialog-body>
				Are you sure you want to log out etc etc
			</template>
		</mw-dialog>
	</span>
</template>

<script type="text/javascript">
import MwDialog from "@c/ui/MwDialog";
import NavItem from "@/components/navigation/Item.vue";
export default {
	name: "Logout",
	data: () => {
		return {
			dialog: false,
		};
	},
	components: {
		NavItem,
		MwDialog,
	},
	methods: {
		logout() {
			const self = this;
			self.$store.dispatch("auth/stop").then(() => {
				if (self.$route.path !== "/") {
					self.$router.replace("/");
				}
			});
		},
	},
};
</script>
"

export default {
	getFields(model) {
		return Object.values(model).filter((field) => field.display);
	},
	uniqueArray(array) {
		return array.filter( (value, index) => array.indexOf(value) === index );
	},
	getEmpty(model) {
		var obj = {};
		Object.values(model).forEach(
			(field) => (obj[field.value] = field.default)
		);
		return obj;
	},
	testRule(rule, object) {
		let match = false;
		let operator = rule.operator;
		let value1 = rule.value;
		let value2 = object[rule.category];

		if (typeof value1 == "string") {
			value1 = [value1];
		}
		if (typeof value2 == "string") {
			value2 = [value2];
		}

		if (operator == "in") {
			operator = "==";
		}
		value1.forEach((v1) => {
			if (typeof v1 == "string") {
				v1 = `'${v1}'`;
			}
			match = value2.find((v2) => {
				if (typeof v1 == "string") {
					v2 = `'${v2}'`;
				}
				var test = `${v1} ${operator} ${v2}`;
				return eval(test);
			});
		});
		return match ? true : false;
	},
	prettyPrint: function(str, strCase) {
		if (!str) {
			return "";
		}
		str = str.split("_").join(" ");
		str = str.split("-").join(" ");
		switch (strCase) {
			case "TITLE":
				var sentence = str.toLowerCase().split(" ");
				for (var i = 0; i < sentence.length; i++) {
					sentence[i] =
						sentence[i][0].toUpperCase() + sentence[i].slice(1);
				}
				return sentence.join(" ");
			case "SENTENCE":
				str = str.toLowerCase();
				str = str[0].toUpperCase() + str.slice(1);
				return str;
			case "LOWER":
				return str.toLowerCase();
			case "UPPER":
				return str.toUpperCase();
			default:
				return str;
		}
	},
	validateEmail(str) {
		if (
			/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
				str
			)
		) {
			return true;
		}
		return false;
	},
	strToKey: function(str) {
		if (!str) {
			return "";
		}
		str = str.split(" ").join("-");
		str = str.replace(/[^\w\s]/gi, "-");
		return str.toLowerCase();
	},
	sortByKey: function(array, key) {
		return array.sort(function(a, b) {
			var x = a[key];
			var y = b[key];
			return x < y ? -1 : x > y ? 1 : 0;
		});
	},
	moveItemInArray(id, array, direction) {
		let delta = direction == "up" ? -1 : 1;
		var index = array.indexOf(id);
		var newIndex = index + delta;
		if (newIndex < 0 || newIndex == array.length) return;
		var indexes = [index, newIndex].sort((a, b) => a - b);
		array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]);
	},
	getPositions(array, property = "position") {
		var keys = Object.keys(array);
		keys = keys.sort((a, b) => {
			var x = array[a][property];
			var y = array[b][property];
			return x < y ? -1 : x > y ? 1 : 0;
		});
		return keys;
	},
	itemsArray(obj, text = "name") {
		return Object.keys(obj).map((id) => {
			return {
				value: id,
				text: obj[id][text],
			};
		});
	},
	upperCaseFirst(str) {
		return str.charAt(0).toUpperCase() + str.substr(1);
	},
	lowerCaseFirst(str) {
		return str.charAt(0).toLowerCase() + str.substr(1);
	},
	average(array){
		const sum = array.reduce((a, b) => a + b, 0);
		const avg = (sum / array.length) || 0;
		return avg;
	},
	closestValue(value, array){
		if( !array.length ){
			return;
		}
		var closest = array.reduce(function(prev, curr) {
			return (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
		});
		return closest;
	}
};

import store from "../store";
import utilities from "./utilities";
export default {
	processResponse(response) {
		let survey = store.state.sections.data[response.section];
		if (!survey || !survey.questions) {
			return;
		}
		let questions = store.state.questions.data;
		let questionTemplates = store.state.questionTemplates.data;
		return survey.questions.map((q) => {
			let question = questions[q];
			if (!question) {
				return false;
			}
			let questionTemplate = questionTemplates[question.question_template];
			let answer = response.data[q];
			let processed_answer = "";
			let score = "";
			if (questionTemplate) {
				var options = questionTemplate.answer_options;
				if (answer) {
					processed_answer = this.processAnswer(answer, options);
					score = this.getScore(answer, questionTemplate);
				}
			}
			return {
				...response,
				response: response.id,
				answer,
				indicator: question.indicator,
				question_template: question.question_template,
				question: q,
				score,
				processed_answer,
			};
		});
	},
	processAnswer(answer, answerOptions) {
		if (typeof answer == "object") {
			let answers = answer.map((a) => {
				return answerOptions[a].text;
			});
			answer = answers.join(", ");
		} else {
			let answerOption = answerOptions[answer];
			if (answerOption) {
				answer = answerOption.text;
			}
		}
		return answer;
	},
	getScore(answer, question_template) {
		let score = null;
		if (!question_template.scored) {
			return score;
		}
		let answerOption = question_template.answer_options[answer];
		if (answerOption) {
			if (answerOption.scored) {
				score = answerOption.score;
			}
		}
		return null == score ? null : Number(score);
	},
	averageResponse(arr, answer_options) {
		let results = arr.map((v) => v.score);
		if (!results.length) {
			return "-";
		}
		results = results.filter((v) => null !== v);
		let average = utilities.average(results);
		let scoreLabels = Object.fromEntries(
			answer_options.map((a) => [Number(a.score), a.text])
		);
		let scores = Object.keys(scoreLabels);
		let closest = utilities.closestValue(average, scores);
		return scoreLabels[closest] || "";
	},
	questionHasConditions(question) {
		if(!question ){
			return false;
		}
		if (question.rules && question.rules.length) {
			return true;
		}
		return false;
	},
	splitRule(rule) {
		let rules = rule.identifier.split(".");
		return {
			section: rules[0],
			question: rules[1],
		};
	},
	// TODO: All of this needs reworking -> a mess
	isVisible(id, currentResponse) {
		let question = store.state.questions.data[id];
		if (this.questionHasConditions(question)) {
			let responses = store.state.sectionResponses.data;
			let test = true;
			let questionRules = question.rules.filter((rule) => !rule.category);
			questionRules.forEach((rule) => {
				let identifiers = this.splitRule(rule);
				let response = currentResponse;
				if (identifiers.section !== currentResponse.section) {
					response =
						store.state.assessment.data.responses[identifiers.section];
					if (typeof response == "object") {
						if (currentResponse["service"]) {
							response = response[currentResponse["service"]];
							// TODO - This fetches the 999 response as default
						} else if (response["Cnj0Smcvlf2292jZm72G"]) {
							response = response["Cnj0Smcvlf2292jZm72G"];
						} else {
							response = Object.values(response)[0];
						}
					}
					response = responses[response];
				}
				let passed = this.evaluateRule(rule, response.data);
				if (!passed) {
					test = passed;
				}
			});
			return test;
		} else {
			return true;
		}
	},

	evaluateRule(rule, data) {
		if (!rule) {
			return true;
		}
		if (!data) {
			return false;
		}

		var visible = false;
		var targetAnswer = rule.value;
		if( !targetAnswer ){
			return true;
		}
		var targetQuestion = this.splitRule(rule).question;
		if (targetQuestion == "any") {
			visible = Object.values(data).findIndex((v) => v == targetAnswer) > -1;
		} else {
			var value = data[targetQuestion];
			if (rule.operator == "in") {
				visible = targetAnswer.indexOf(value) > -1;
			} else {
				var test = `'${value}' ${rule.operator} '${targetAnswer}'`;
				visible = eval(test);
			}
		}
		return visible;
	},
	hideAnswered(questions, response) {
		let hide = store.state.hide_answered;
		let values = response.data;
		if (!hide) {
			return questions;
		} else {
			return questions.filter(
				(q) => values[q] == "" || typeof values[q] == "undefined"
			);
		}
	},
};

export default {
	organisationLeadRespondent(state, getters){
		return (id) => {
			let assessment = getters.organisationAssessment(id);
			if( !assessment ){
				return false;
			}
			assessment = state.assessments.data[assessment]
			let leadRespondent = Object.keys(assessment.users).find( a => {
				return assessment.users[a] == "leadRespondent";
			})
			return state.users.data[leadRespondent]
		}
	},
	organisationAssessments(state) {
		return (id) => {
			let assessments = Object.keys(state.assessments.data).filter((a) => {
				return state.assessments.data[a].organisation == id;
			});
			return assessments;
		};
	},
	organisationAssessment(_, getters){
		return (id) => {
			let assessments = getters.organisationAssessments(id);
			return assessments[0] || false
		}
	},
	organisationResponses(state, getters) {
		return (id) => {
			let sections = state.config.data.sections;
			let assessment = getters.organisationAssessment(id);
			assessment = state.assessments.data[assessment];
			if (!assessment) {
				return [];
			}
			if( !assessment.responses ){
				return [];
			}
			return sections.filter(s => assessment.responses[s]).flatMap((section) => {
				let response = assessment.responses[section];
				if (typeof response == "string") {
					return { section: section, response: response };
				} else {
					return Object.keys(response).map((r) => {
						return { section: section, response: response[r], rule: r };
					});
				}
			});
		};
	},
};

export default {
	firestorePath: "organisations",
	firestoreRefType: "collection",
	moduleName: "organisations",
	statePropName: "data",
	namespaced: true,
	state: {},
	sync: {
		where: [["status", "in", ["published"]]],
		defaultValues: {
			status: "published",
		},
	},
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			dispatch("assessments/fetchAndAdd", { clauses: { where: [["organisation", "==", doc.id]] } },  {root: true});
			return updateStore(doc);
		},
	},

	getters: {},
	mutations: {},
	actions: {},
	model: {},
};
